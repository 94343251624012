import React, { Component } from 'react';
import LogoWhite from './images/assets/logo_white.png';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Post from './components/Post';
import Gallerie from './components/Gallerie';

import ReactGA from 'react-ga';
import firebase from './components/Firebase';


class App extends Component {
  state = {
    toggle: "posts",
    posts: []
  }

  toggle = (page) => {
    console.log("toggle");
    this.setState({
        toggle: page
    })
  }

  initializeReactGA = () => {
    ReactGA.initialize('UA-102224759-1');
    ReactGA.pageview('/china-blog');
}

componentWillMount(){
  this.startFirebase();
}

startFirebase = () => {
  console.log("firebasestarted");
  const db = firebase.firestore();
  const col = db.collection('travelblog');

  let helperStorageArray = [];

  col.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
          helperStorageArray.push(doc.data());
      })
      if(this.state.posts.length < helperStorageArray.length){
          this.setState({
              posts: helperStorageArray
          })
      } else {
          console.log("nope");
      }
  })
  console.log("storage_gallery", helperStorageArray);
}

componentDidMount = () => {
  this.initializeReactGA();
}

  render() {
    return (
      <div className="App">
        <div className="header">
          <div className="headerImage"></div>
          <img src={LogoWhite} className="headerLogo" alt="logo" />
          
        </div>
        <div id="content">

          <div className="navBar">
                <div className="logo_in_nav">
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-6">
                        <div onClick={() => {this.toggle("posts")}}>
                            <div className={"nav-elem text-right" + (this.state.toggle === "posts" ? (" active") : (""))}>
                                <span className="banner">Posts</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div onClick={() => {this.toggle("gallerie")}}>
                            <div className={"nav-elem text-left" + (this.state.toggle === "gallerie" ? (" active") : (""))}>
                                Gallerie
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
          <div className={(this.state.toggle === "posts" ? ("show") : ("hide"))}>
            <Post posts={this.state.posts}/>
          </div>
          <div className={(this.state.toggle === "gallerie" ? ("show") : ("hide"))}>
            <Gallerie posts={this.state.posts}/>
          </div>
        </div>
        <div className="footer">
          Teneriffa 2k19 - Let's go!
        </div>
      </div>
    );
  }
}

export default App;
