import React, { Component } from 'react';

import firebase from './Firebase';

import BildUndText from './BildUndText';

class Post extends Component {

    generatePosts = () => {
        const posts = this.props.posts;
        let divArr = [];
        
        for(var elem of posts){
            console.log("elem", elem);
            divArr.push(
                <BildUndText postData={elem}></BildUndText>
            );
        }
        return divArr;
    }
    
    render(){
        return(
            <div className="posts">
                {this.generatePosts()}
                
            </div>
        );
    }
}

export default Post;