import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipeGallery} from 'react-photoswipe';

import { LazyLoadImage } from 'react-lazy-load-image-component';

let images = [];
let galleryThumbnails = [];

class BildUndText extends Component{
    state = {
        open: false,
        isOpen: true
      };

    renderText = (name) => {
        let array = [];
        if(name === this.props.postData.headline){
            console.log("mathc");
            for(var i in this.props.postData.absaetze){
                array.push(
                    <div key={i} className="absatz">
                        {this.props.postData.absaetze[i]}
                    </div>
                );
                console.log("array",array);
            }
        }
        return array;
    }

    createImageLightbox = (name) => {
        images = [];
        galleryThumbnails = [];
        console.log("props:", this.props.postData);
        var counter = 0;
        for(var i of this.props.postData.images){
            images = [...images, {source: process.env.PUBLIC_URL + '/images/' + i + ".jpg"}];
            if( counter === 0){
                galleryThumbnails = [...galleryThumbnails, {
                    src: process.env.PUBLIC_URL + '/images/' + i + ".jpg",
                    thumbnail: process.env.PUBLIC_URL + '/images/' + i + ".jpg",
                    w: 2000,
                    h: 1333
                }]
            } else {
                galleryThumbnails = [...galleryThumbnails, {
                    src: process.env.PUBLIC_URL + '/images/' + i + ".jpg",
                    thumbnail: process.env.PUBLIC_URL + '/images/thumbs/' + i + ".jpg",
                    w: 2000,
                    h: 1333
                }]
            }
        }
        counter++;
    }

    getThumbnailContent = (item) => {
        return (
            <div key={item} className=" imageWrapper">
                {/*<img src={item.thumbnail} className="galleryImage" width={200} height={133}/>*/}
                <LazyLoadImage
                    src={item.thumbnail} // use normal <img> attributes as props
                    className="galleryImage"
                    height="200"
                    width="133"
                    effect="blur"
                />
            </div>
        );
      }

    showMore = (index) => {
        this.setState({
            open: index,
          });
    }
    render() {
        galleryThumbnails = [];
        images = [];
        console.log("date: ", this.props.postData.date);
        this.createImageLightbox(this.props.postData.headline);
        return(
            <div className="container">
                <div className="row post">
                    {console.log(this.props.postData.orientation, "i")}
                    <div className={"col-sm-6" + (this.props.postData.orientation === "left" ? (" order-sm-last") : (" order-sm-first") )}>
                        <PhotoSwipeGallery items={galleryThumbnails} thumbnailContent={this.getThumbnailContent}/>
                    </div>
                    <div className="col-sm-6">
                        <div className="headline">{ this.props.postData.headline }</div>
                        <div className="date">{ this.props.postData.date }</div>
                        <hr></hr>
                        <div className="content text">
                            { this.props.postData.short }
                            {this.state.open === this.props.i && (
                                <div>
                                    {this.renderText(this.props.postData.headline)}
                                </div>
                            )}
                                {this.state.open === this.props.i ? (
                                    <Button onClick={() => {this.showMore(false)}} variant="outlined" color="primary" className="moreBtn">
                                        weniger
                                    </Button>
                                ) : (
                                    <Button onClick={() => {this.showMore(this.props.i)}} variant="outlined" color="primary" className="moreBtn">
                                        mehr
                                    </Button>
                                )}
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BildUndText;