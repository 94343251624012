import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyAiAKBWgB11-MPlGz8pZDPyuHLzz161nkw",
    authDomain: "waiterapp-300f0.firebaseapp.com",
    databaseURL: "https://waiterapp-300f0.firebaseio.com",
    projectId: "waiterapp-300f0",
    storageBucket: "waiterapp-300f0.appspot.com",
    messagingSenderId: "807110402250"
  };

firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export default firebase;